import React from "react";
import {Link} from "react-router-dom";
import {Button, Col, Container, Nav, NavItem, NavLink, Row, UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FAB from "@fortawesome/free-brands-svg-icons";
import {FACEBOOK, INSTAGRAM, TWITTER} from "../../data/links";
import {LORO} from "../../data/settings";
import {AppLanguage, AppRoutes, Locale, Route} from "../../i18n";

interface FooterProps {

}

interface FooterState {

}

export class Footer extends React.Component<FooterProps, FooterState> {
    render() {
        return <>
            <footer className="footer">
                <Container>
                    <Row className="row-grid align-items-center mb-5">
                        <Col lg="6">
                            <h3 className="text-warning font-weight-light mb-2">
                                {Locale.format('footer.titular')}
                            </h3>
                            <h4 className="mb-0 font-weight-light">
                                {Locale.format('footer.subtitular')}
                            </h4>
                        </Col>
                        <Col className="text-lg-right btn-wrapper" lg="6">
                            <Button
                                className="btn-icon-only rounded-circle"
                                color="twitter"
                                href={TWITTER}
                                id="tooltipTwitter"
                                target="_blank"
                            >
                                <span className="btn-inner--icon">
                                    <FontAwesomeIcon icon={FAB.faTwitter}/>
                                </span>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tooltipTwitter">
                                {Locale.format('footer.twitter')}
                            </UncontrolledTooltip>
                            <Button
                                className="btn-icon-only rounded-circle ml-1"
                                color="facebook"
                                href={FACEBOOK}
                                id="tooltipFacebook"
                                target="_blank"
                            >
                                <span className="btn-inner--icon">
                                    <FontAwesomeIcon icon={FAB.faFacebookF}/>
                                </span>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tooltipFacebook">
                                {Locale.format('footer.facebook')}
                            </UncontrolledTooltip>
                            <Button
                                className="btn-icon-only rounded-circle ml-1"
                                color="instagram"
                                href={INSTAGRAM}
                                id="tooltipInstagram"
                                target="_blank"
                            >
                                <span className="btn-inner--icon">
                                    <FontAwesomeIcon icon={FAB.faInstagram}/>
                                </span>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tooltipInstagram">
                                {Locale.format('footer.instagram')}
                            </UncontrolledTooltip>
                            {/*}<Button
                                className="btn-icon-only rounded-circle ml-1"
                                color="twitter"
                                href={TELEGRAM}
                                id="tooltipTelegram"
                                target="_blank"
                            >
                                <span className="btn-inner--icon">
                                    <FontAwesomeIcon icon={FAB.faTelegramPlane}/>
                                </span>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tooltipTelegram">
                                {Locale.format('footer.telegram')}
                            </UncontrolledTooltip>{*/}
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="align-items-center justify-content-md-between">
                        <Col lg="5" className="order-0 order-lg-0">
                            <div className="copyright">
                                <b>{Locale.format('footer.copyright')}</b> © {new Date().getFullYear()}
                            </div>
                        </Col>
                        <Col lg="2" className="order-2 order-lg-1">
                            <Nav className="nav-footer justify-content-center">
                                <NavItem>
                                    <NavLink href="https://vercel.com?utm_source=pc-santiago" target="_blank">
                                        <img
                                            alt="..."
                                            src={require('../../assets/img/vercel' + (
                                                Locale.getLang() === AppLanguage.Galician || Locale.getLang() === AppLanguage.Spanish ? ""
                                                    : "-en"
                                            ) + '.svg')}
                                            style={{height: "2.25rem"}}
                                        />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col lg="5" className="order-1 order-lg-2">
                            <Nav className="nav-footer justify-content-end">
                                <NavItem>
                                    <NavLink
                                        to={Route(AppRoutes.Agradecimientos)}
                                        tag={Link}
                                    >
                                        {Locale.format('agradecimientos.titulo')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to={Route(AppRoutes.Contacto)}
                                        tag={Link}
                                    >
                                        {Locale.format('contacto.titulo')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href={LORO}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {Locale.format('gen.intranet')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>;
    }
}