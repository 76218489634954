import React from "react";
import ReactGA from 'react-ga';
import {RouteComponentProps} from "react-router";

ReactGA.initialize("UA-169626874-1");

const withTracker = (WrappedComponent: any, router: RouteComponentProps) => {
    const trackPage = (page: string) => {
        ReactGA.set({page});
        ReactGA.pageview(page);
    };

    return class extends React.Component<RouteComponentProps> {
        componentDidMount() {
            const page = this.props.location.pathname + this.props.location.search;
            trackPage(page);
        }

        componentDidUpdate(prevProps: RouteComponentProps) {
            const currentPage = prevProps.location.pathname + prevProps.location.search;
            const nextPage = this.props.location.pathname + this.props.location.search;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} {...router} />;
        }
    };
};

export default withTracker;
