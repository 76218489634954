import React from "react";
import {Footer, Navbar} from "./comps";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {RouteProps} from "react-router";


interface AppLayoutProps {
    children: React.ReactNode
}

export const AppLayout = (props: AppLayoutProps) => {
    let router = {
        history: useHistory(),
        location: useLocation(),
        match: useRouteMatch(),
    };

    return <>
        <Navbar
            {...router}
        />
        <main>
            {
                React.isValidElement<RouteProps>(props.children) ?
                    React.cloneElement(props.children, {
                        ...props.children.props
                    }) :
                    props.children
            }
        </main>
        {/* <Contact/> */}
        <Footer/>
    </>;
};

export default AppLayout;