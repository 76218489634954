import React from "react";
import {Helmet} from "react-helmet";
import {AppRoutes, Locale, Route} from "../i18n";
import {Button, Col, Container, Row,} from "reactstrap";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

interface Error404Props {
}

interface Error404State {
}

export class Error404 extends React.Component<Error404Props, Error404State> {
    constructor(props: Error404Props) {
        super(props);
        this.state = {}
    }

    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("error.404.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-darker">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('error.404.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('error.404.subtitular')}
                                    </p>
                                    <Button
                                        color="secondary"
                                        style={{color: "var(--orange)"}}
                                        type="button"
                                        tag={Link}
                                        to={Route(AppRoutes.Inicio)}
                                    >
                                        <FontAwesomeIcon icon={FA.faHome}/>
                                        {" " + Locale.format('error.404.volver')}
                                    </Button>
                                </Col>
                                <Col lg="5">
                                    <img
                                        alt="..." className="img-fluid rounded shadow"
                                        src={require('../assets/img/404.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-secondary" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
            </div>
        </>;
    }
}