import React from "react";
import {Helmet} from "react-helmet";
import {Locale} from "../../i18n";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row
} from "reactstrap";
import {url, memorias} from '../../assets/files';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";

interface MemoriasProps {
}

interface MemoriasState {
    memoria: string;
}

export class Memorias extends React.Component<MemoriasProps, MemoriasState> {
    private memoriasAnos = Object.keys(memorias);

    constructor(props: MemoriasProps) {
        super(props);

        this.state = {
            memoria: this.memoriasAnos[this.memoriasAnos.length - 1]
        }
    }

    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("comunicacion.memorias.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-success">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('comunicacion.memorias.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('comunicacion.memorias.subtitular')}
                                    </p>
                                </Col>
                                <Col lg="5">
                                    <img
                                        alt="..." className="img-fluid rounded shadow"
                                        src={require('../../assets/img/comunicacion/memorias/p1.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section section-sm">
                    <Container>
                        <Row>
                            <Col lg="2" className="d-none d-lg-block"/>
                            <Col lg="8">
                                <Card className="shadow">
                                    <CardHeader>
                                        {Locale.format('comunicacion.memorias.anual')}
                                    </CardHeader>
                                    <CardBody>
                                        <Button
                                            color="warning" outline type="button" style={{width: "100%"}}
                                            onClick={e => {
                                                e.preventDefault();
                                                window.open(url(memorias[this.state.memoria].full))
                                            }}
                                        >
                                            <FontAwesomeIcon icon={FA.faFileDownload}/>
                                            &nbsp;&nbsp;&nbsp;
                                            {Locale.format('comunicacion.memorias.anual.descargar')}
                                            &nbsp;
                                            {this.state.memoria}
                                        </Button>
                                    </CardBody>
                                    <CardFooter>
                                        <nav aria-label="..." className="mt-3">
                                            <Pagination
                                                className="pagination justify-content-center pagination-lg"
                                                listClassName="justify-content-center pagination-lg"
                                            >
                                                <PaginationItem
                                                    className={this.memoriasAnos.indexOf(this.state.memoria) === 0 ? "disabled" : ""}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        if (this.memoriasAnos.indexOf(this.state.memoria) > 0) {
                                                            this.setState({memoria: this.memoriasAnos[this.memoriasAnos.indexOf(this.state.memoria) - 1]});
                                                        }
                                                    }}
                                                >
                                                    <PaginationLink>
                                                        <FontAwesomeIcon icon={FA.faChevronLeft}/>
                                                    </PaginationLink>
                                                </PaginationItem>
                                                <PaginationItem className="active">
                                                    <PaginationLink
                                                        className="bg-warning"
                                                        style={{borderColor: "var(--orange)", cursor: "default"}}
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        {this.state.memoria}
                                                    </PaginationLink>
                                                </PaginationItem>
                                                <PaginationItem
                                                    className={this.memoriasAnos.indexOf(this.state.memoria) === (this.memoriasAnos.length - 1) ? "disabled" : ""}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        if (this.memoriasAnos.indexOf(this.state.memoria) < (this.memoriasAnos.length - 1)) {
                                                            this.setState({memoria: this.memoriasAnos[this.memoriasAnos.indexOf(this.state.memoria) + 1]})
                                                        }
                                                    }}
                                                >
                                                    <PaginationLink>
                                                        <FontAwesomeIcon icon={FA.faChevronRight}/>
                                                    </PaginationLink>
                                                </PaginationItem>
                                            </Pagination>
                                        </nav>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>;
    }
}