import React from "react";
import {Link, RouteComponentProps} from 'react-router-dom';

import {Badge, Button, Card, CardBody, CardImg, Col, Container, Row, UncontrolledCarousel} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {shuffle} from "../data/utils";
import {Helmet} from "react-helmet";
import {AppRoutes, Locale, Route} from "../i18n";

interface InicioProps extends RouteComponentProps {
}

interface InicioState {
}


export class Inicio extends React.Component<InicioProps, InicioState> {
    private items = [
        {src: require("../assets/img/inicio/c1.jpg"), altText: "", caption: "", header: ""},
        {src: require("../assets/img/inicio/c2.jpg"), altText: "", caption: "", header: ""},
        {src: require("../assets/img/inicio/c3.jpg"), altText: "", caption: "", header: ""},
        {src: require("../assets/img/inicio/c4.jpg"), altText: "", caption: "", header: ""},
    ];
    private ref = React.createRef<HTMLElement>();

    constructor(props: InicioProps) {
        super(props);
        this.state = {}
    }

    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("inicio.titulo")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-lg section-shaped pb-250">
                    <div className="shape shape-style-1 shape-orange">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="py-lg-md d-flex">
                        <div className="col px-0">
                            <Row>
                                <Col lg="6">
                                    <h1 className="display-3 text-white">
                                        {Locale.format("inicio.titular")}
                                        <span>{Locale.format("inicio.subtitular")}</span>
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format("inicio.descripcion")}
                                    </p>
                                    <div className="btn-wrapper">
                                        <Button
                                            className="btn-icon mb-3 mb-sm-0"
                                            color="warning"
                                            tag={Link}
                                            to={Route(AppRoutes.Voluntariado)}
                                        >
                                            <span className="btn-inner--icon mr-1">
                                                <FontAwesomeIcon icon={FA.faUserPlus}/>
                                            </span>
                                            <span
                                                className="btn-inner--text">{Locale.format("voluntariado.titulo")}</span>
                                        </Button>
                                        <Button
                                            className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                            color="default"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (this.ref.current !== null)
                                                    window.scrollTo(0, this.ref.current.offsetTop);
                                            }}
                                        >
                                            <span className="btn-inner--icon mr-1">
                                                <FontAwesomeIcon icon={FA.faChevronDown}/>
                                            </span>
                                            <span className="btn-inner--text">
                                                {Locale.format("gen.vermas")}
                                            </span>
                                        </Button>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                                        <UncontrolledCarousel items={shuffle(this.items)}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
                {/* 1st Hero Variation */}
            </div>
            <section className="section section-lg pt-lg-0 mt--200">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="12">
                            <Row className="row-grid">
                                <Col lg="4">
                                    <Card className="card-lift--hover shadow border-0">
                                        <CardBody className="py-4">
                                            <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                                                <FontAwesomeIcon icon={FA.faUserShield}/>
                                            </div>
                                            <h6 className="text-warning text-uppercase">
                                                {Locale.format('inicio.tarjetas.1.titulo')}
                                            </h6>
                                            <p className="description mt-3">
                                                {Locale.format('inicio.tarjetas.1.descripcion')}
                                            </p>
                                            <Button
                                                className="mt-4"
                                                color="warning"
                                                to={Route(AppRoutes.Somos)}
                                                tag={Link}
                                            >
                                                {Locale.format("gen.vermas")}
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="4">
                                    <Card className="card-lift--hover shadow border-0">
                                        <CardBody className="py-4">
                                            <div className="icon icon-shape icon-shape-info rounded-circle mb-4">
                                                <FontAwesomeIcon icon={FA.faChalkboard}/>
                                            </div>
                                            <h6 className="text-info text-uppercase">
                                                {Locale.format('inicio.tarjetas.2.titulo')}
                                            </h6>
                                            <p className="description mt-3">
                                                {Locale.format('inicio.tarjetas.2.descripcion')}
                                            </p>
                                            <Button
                                                className="mt-4"
                                                color="info"
                                                to={Route(AppRoutes.Formacion)}
                                                tag={Link}
                                            >
                                                {Locale.format("gen.vermas")}
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="4">
                                    <Card className="card-lift--hover shadow border-0">
                                        <CardBody className="py-4">
                                            <div className="icon icon-shape icon-shape-danger rounded-circle mb-4">
                                                <FontAwesomeIcon icon={FA.faPlay}/>
                                            </div>
                                            <h6 className="text-danger text-uppercase">
                                                {Locale.format('inicio.tarjetas.3.titulo')}
                                            </h6>
                                            <p className="description mt-3">
                                                {Locale.format('inicio.tarjetas.3.descripcion')}
                                            </p>
                                            <Button
                                                className="mt-4"
                                                color="danger"
                                                to={Route(AppRoutes.Intervenciones)}
                                                tag={Link}
                                            >
                                                {Locale.format("gen.vermas")}
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section section-lg" ref={this.ref}>
                <Container>
                    <Row className="row-grid align-items-center">
                        <Col className="order-md-2" md="6">
                            <img alt="..." className="img-fluid floating" src={require('../assets/img/inicio/l1.jpg')}/>
                        </Col>
                        <Col className="order-md-1" md="6">
                            <div className="pr-md-5">
                                <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                                    <FontAwesomeIcon icon={FA.faFlag}/>
                                </div>
                                <h3>{Locale.format("inicio.intervencions.titulo")}</h3>
                                <p>{Locale.format("inicio.intervencions.subtitulo")}</p>
                                <ul className="list-unstyled mt-5">
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="warning">
                                                    <FontAwesomeIcon icon={FA.faCheck}/>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">{Locale.format("inicio.intervencions.l1")}</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="warning">
                                                    <FontAwesomeIcon icon={FA.faCheck}/>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">{Locale.format("inicio.intervencions.l2")}</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="warning">
                                                    <FontAwesomeIcon icon={FA.faCheck}/>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">{Locale.format("inicio.intervencions.l3")}</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="warning">
                                                    <FontAwesomeIcon icon={FA.faCheck}/>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">{Locale.format("inicio.intervencions.l4")}</h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section bg-secondary">
                <Container>
                    <Row className="row-grid align-items-center">
                        <Col md="6">
                            <Card className="bg-warning shadow border-0">
                                <CardImg alt="..." src={require("../assets/img/inicio/f1.jpg")} top/>
                                <blockquote className="card-blockquote">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="svg-bg"
                                        preserveAspectRatio="none"
                                        viewBox="0 0 583 95"
                                    >
                                        <polygon className="fill-warning" points="0,52 583,95 0,95"/>
                                        <polygon className="fill-warning" opacity=".2" points="0,42 583,95 683,0 0,95"/>
                                    </svg>
                                    <h4 className="display-3 font-weight-bold text-white">
                                        {Locale.format("inicio.formacion.1.titulo")}
                                    </h4>
                                    <p className="lead text-italic text-white">
                                        {Locale.format("inicio.formacion.1.subtitulo")}
                                    </p>
                                </blockquote>
                            </Card>
                        </Col>
                        <Col md="6">
                            <div className="pl-md-5">
                                <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                                    <FontAwesomeIcon icon={FA.faChalkboardTeacher}/>
                                </div>
                                <h3>{Locale.format("inicio.formacion.2.titulo")}</h3>
                                <p className="lead">{Locale.format("inicio.formacion.2.subtitulo")}</p>
                                <p>{Locale.format("inicio.formacion.2.t1")}</p>
                                <p>{Locale.format("inicio.formacion.2.t2")}</p>
                                <Button
                                    className="btn-link font-weight-bold text-warning mt-5"
                                    to={Route(AppRoutes.Formacion)}
                                    tag={Link}
                                    onClick={e => e.preventDefault()}
                                >
                                    {Locale.format("gen.vermas")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section pb-0 bg-gradient-warning">
                <Container>
                    <Row className="row-grid align-items-center">
                        <Col className="order-lg-2 ml-lg-auto" md="6">
                            <div className="position-relative pl-md-5" style={{zIndex: 1}}>
                                <img
                                    alt="..." className="img-center img-fluid"
                                    src={require('../assets/img/inicio/r1.jpg')}
                                />
                            </div>
                        </Col>
                        <Col className="order-lg-1" lg="6">
                            <div className="d-flex px-3">
                                <div>
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-warning">
                                        <FontAwesomeIcon icon={FA.faNetworkWired}/>
                                    </div>
                                </div>
                                <div className="pl-4">
                                    <h4 className="display-3 text-white">{Locale.format("inicio.recursos.titulo")}</h4>
                                    <p className="text-white">{Locale.format("inicio.recursos.descripcion")}</p>
                                </div>
                            </div>
                            <Card className="shadow shadow-lg--hover mt-5">
                                <CardBody>
                                    <div className="d-flex px-3">
                                        <div>
                                            <div
                                                className="icon icon-shape bg-gradient-purple rounded-circle text-white">
                                                <FontAwesomeIcon icon={FA.faCar}/>
                                            </div>
                                        </div>
                                        <div className="pl-4">
                                            <h5 className="title text-purple">
                                                {Locale.format('inicio.recursos.tarjetas.1.titulo')}
                                            </h5>
                                            <p>
                                                {Locale.format('inicio.recursos.tarjetas.1.descripcion')}
                                            </p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="shadow shadow-lg--hover mt-5" style={{zIndex: 1}}>
                                <CardBody>
                                    <div className="d-flex px-3">
                                        <div>
                                            <div
                                                className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                                <FontAwesomeIcon icon={FA.faBook}/>
                                            </div>
                                        </div>
                                        <div className="pl-4">
                                            <h5 className="title text-success">
                                                {Locale.format('inicio.recursos.tarjetas.2.titulo')}
                                            </h5>
                                            <p>
                                                {Locale.format('inicio.recursos.tarjetas.2.descripcion')}
                                            </p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {/* SVG separator */}
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon className="fill-secondary" points="2560 0 2560 100 0 100"/>
                    </svg>
                </div>
            </section>
            <section className="section section-lg pt-7 bg-secondary">
                <Container>
                    <Card className="bg-gradient-default shadow-lg border-0">
                        <div className="p-5">
                            <Row className="align-items-center">
                                <Col lg="8">
                                    <h3 className="text-white">{Locale.format("inicio.contacto.titulo")}</h3>
                                    <p className="lead text-white mt-3">
                                        {Locale.format("inicio.contacto.descripcion")}
                                    </p>
                                </Col>
                                <Col className="ml-lg-auto" lg="3">
                                    <Button
                                        block
                                        className="btn-white"
                                        color="default"
                                        to={Route(AppRoutes.Contacto)}
                                        size="lg"
                                        tag={Link}
                                    >
                                        {Locale.format("inicio.contacto.boton")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Container>
            </section>
        </>;
    }
}
