import React from "react";
import {Helmet} from "react-helmet";
import {Locale} from "../i18n";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {Validadores} from "../data/utils";
import moment from "moment";
import ReactDatetime from 'react-datetime';
import {CONTACTO_EMAIL} from "../data/links";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA} from "../data/settings";

interface VoluntariadoProps {

}

interface Formulario {
    nombre: string;
    apellidos: string;
    email: string;
    movil: string;
    preferencia: string;
    dni: string;
    fechaNacimiento: string;
    telefono: string;
    direccion: string;
    profesion: string,
    formacion: string,
    otros: string,
    carnets: string;
    aviso: string;
}

interface VoluntariadoState {
    step: number;
    datos: Formulario;
    enviando: boolean;
    enviado: boolean;
    invalidos: string[];
    recaptcha: boolean;
}

export class Voluntariado extends React.Component<VoluntariadoProps, VoluntariadoState> {
    private iframe = React.createRef<HTMLIFrameElement>();
    private formulario = "https://docs.google.com/forms/u/0/d/e/1FAIpQLScI0S7hUCOAvpr10FHx4echPfWoo2RWE64YsLgSVyVpkzKcWg/formResponse";
    private valores = {
        // 0
        nombre: "entry.833040487",
        apellidos: "entry.1209806008",
        email: "entry.738022722",
        movil: "entry.671645012",
        preferencia: "entry.1796385302",
        // 1
        dni: "entry.993900284",
        fechaNacimiento: "entry.274512528",
        telefono: "entry.962428409",
        direccion: "entry.12581418",
        profesion: "entry.953549054",
        formacion: "entry.1253133039",
        otros: "entry.134950767",
        carnets: "entry.1707005742",
        // 2
        aviso: "entry.2123947656"
    };

    constructor(props: VoluntariadoProps) {
        super(props);
        this.state = {
            step: 0,
            datos: {
                nombre: "",
                apellidos: "",
                email: "",
                movil: "",
                preferencia: "",
                dni: "",
                fechaNacimiento: "",
                telefono: "",
                direccion: "",
                profesion: "",
                formacion: "",
                otros: "",
                carnets: "",
                aviso: "entry.2123947656"
            },
            enviando: false,
            enviado: false,
            invalidos: [],
            recaptcha: false
        }
    }

    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("voluntariado.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-default">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('voluntariado.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('voluntariado.subtitular')}
                                    </p>
                                </Col>
                                <Col lg="5">
                                    <img
                                        alt="..."
                                        className="img-fluid rounded shadow"
                                        src={require('../assets/img/voluntariado/solicitud.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                {/* 1st Hero Variation */}
                <section className="section section-sm">
                    <Container>
                        <Row>
                            <Col lg="2" className="d-none d-lg-block"/>
                            <Col lg="8" className="mt--7">
                                <Card className="shadow">
                                    <CardHeader>
                                        {Locale.format('voluntariado.form.step.' + this.state.step)}
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{overflow: 'hidden'}}>
                                            <iframe
                                                src="https://docs.google.com/forms/d/e/1FAIpQLScI0S7hUCOAvpr10FHx4echPfWoo2RWE64YsLgSVyVpkzKcWg/viewform?embedded=true"
                                                frameBorder="0" style={{
                                                    width: '100%',
                                                    height: '640px'
                                                }}
                                            >
                                                Cargando...
                                            </iframe>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/*}<Col lg="8" className="mt--7">
                                <Card className="shadow">
                                    <CardHeader>
                                        {Locale.format('voluntariado.form.step.' + this.state.step)}
                                    </CardHeader>
                                    <CardBody>
                                        <Form className="mt-2">
                                            {(() => {
                                                if (this.state.enviado) {
                                                    return <>
                                                        <p>{Locale.format('voluntariado.form.enviado.t1')}</p>
                                                        <p>{Locale.format('voluntariado.form.enviado.t2') + " "}
                                                            <a href={"mailto:" + CONTACTO_EMAIL}>{CONTACTO_EMAIL}</a>.
                                                        </p>
                                                    </>;
                                                }
                                                switch (this.state.step) {
                                                    case 0:
                                                        return <>
                                                            <Row>
                                                                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                                    <FormGroup>
                                                                        <label className="form-control-label"
                                                                               htmlFor="input-nombre">
                                                                            {Locale.format('voluntariado.form.step.0.nombre')}
                                                                        </label>
                                                                        <Input
                                                                            value={this.state.datos.nombre}
                                                                            className={"form-control" + (this.state.invalidos.indexOf('nombre') > -1 ? " form-control-error" : "")}
                                                                            id="input-nombre"
                                                                            placeholder={Locale.format('voluntariado.form.step.0.nombre.pista')}
                                                                            type="text"
                                                                            onChange={e => {
                                                                                let invalidos = [...this.state.invalidos];
                                                                                const valido = e.target.value !== "";
                                                                                if (!valido && this.state.invalidos.indexOf("nombre") === -1)
                                                                                    invalidos = [...this.state.invalidos, "nombre"];
                                                                                else if (valido && this.state.invalidos.indexOf("nombre") > -1)
                                                                                    invalidos.splice(this.state.invalidos.indexOf("nombre"));
                                                                                this.setState({
                                                                                    datos: {
                                                                                        ...this.state.datos,
                                                                                        nombre: e.target.value
                                                                                    },
                                                                                    invalidos: invalidos
                                                                                })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xs="12" sm="12" md="8" lg="8" xl="8">
                                                                    <FormGroup>
                                                                        <label className="form-control-label"
                                                                               htmlFor="input-apellidos">
                                                                            {Locale.format('voluntariado.form.step.0.apellidos')}
                                                                        </label>
                                                                        <Input
                                                                            value={this.state.datos.apellidos}
                                                                            className={"form-control" + (this.state.invalidos.indexOf('apellidos') > -1 ? " form-control-error" : "")}
                                                                            id="input-apellidos"
                                                                            placeholder={Locale.format('voluntariado.form.step.0.apellidos.pista')}
                                                                            type="text"
                                                                            onChange={e => {
                                                                                let invalidos = [...this.state.invalidos];
                                                                                const valido = e.target.value !== "";
                                                                                if (!valido && this.state.invalidos.indexOf("apellidos") === -1)
                                                                                    invalidos = [...this.state.invalidos, "apellidos"];
                                                                                else if (valido && this.state.invalidos.indexOf("apellidos") > -1)
                                                                                    invalidos.splice(this.state.invalidos.indexOf("apellidos"));
                                                                                this.setState({
                                                                                    datos: {
                                                                                        ...this.state.datos,
                                                                                        apellidos: e.target.value
                                                                                    },
                                                                                    invalidos: invalidos
                                                                                })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <FormGroup>
                                                                <label className="form-control-label"
                                                                       htmlFor="input-email">
                                                                    {Locale.format('voluntariado.form.step.0.email') + " "}
                                                                    <FontAwesomeIcon icon={FA.faQuestionCircle}
                                                                                     id="emailAyuda"
                                                                                     data-placement="top"
                                                                                     style={{borderBottom: "1pt dotted"}}/>
                                                                    <UncontrolledTooltip delay={0} placement="top"
                                                                                         target="emailAyuda">
                                                                        {Locale.format('voluntariado.form.step.0.email.ayuda')}
                                                                    </UncontrolledTooltip>
                                                                </label>
                                                                <Input
                                                                    value={this.state.datos.email}
                                                                    className={"form-control" + (this.state.invalidos.indexOf('email') > -1 ? " form-control-error" : "")}
                                                                    id="input-email"
                                                                    placeholder={Locale.format('voluntariado.form.step.0.email.pista')}
                                                                    type="text"
                                                                    onChange={e => {
                                                                        let invalidos = [...this.state.invalidos];
                                                                        const valido = Validadores.email(e.target.value);
                                                                        if (!valido && this.state.invalidos.indexOf("email") === -1)
                                                                            invalidos = [...this.state.invalidos, "email"];
                                                                        else if (valido && this.state.invalidos.indexOf("email") > -1)
                                                                            invalidos.splice(this.state.invalidos.indexOf("email"));
                                                                        this.setState({
                                                                            datos: {
                                                                                ...this.state.datos,
                                                                                email: e.target.value
                                                                            },
                                                                            invalidos: invalidos
                                                                        })
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <label className="form-control-label"
                                                                       htmlFor="input-movil">
                                                                    {Locale.format('voluntariado.form.step.0.movil') + " "}
                                                                    <FontAwesomeIcon icon={FA.faQuestionCircle}
                                                                                     id="movilAyuda"
                                                                                     data-placement="top"
                                                                                     style={{borderBottom: "1pt dotted"}}/>
                                                                    <UncontrolledTooltip delay={0} placement="top"
                                                                                         target="movilAyuda">
                                                                        {Locale.format('voluntariado.form.step.0.movil.ayuda')}
                                                                    </UncontrolledTooltip>
                                                                </label>
                                                                <Input
                                                                    value={this.state.datos.movil}
                                                                    className={"form-control" + (this.state.invalidos.indexOf('movil') > -1 ? " form-control-error" : "")}
                                                                    id="input-movil"
                                                                    placeholder={Locale.format('voluntariado.form.step.0.movil.pista')}
                                                                    type="text"
                                                                    onChange={e => {
                                                                        let invalidos = [...this.state.invalidos];
                                                                        const valido = Validadores.movil(e.target.value);
                                                                        if (!valido && this.state.invalidos.indexOf("movil") === -1)
                                                                            invalidos = [...this.state.invalidos, "movil"];
                                                                        else if (valido && this.state.invalidos.indexOf("movil") > -1)
                                                                            invalidos.splice(this.state.invalidos.indexOf("movil"));
                                                                        this.setState({
                                                                            datos: {
                                                                                ...this.state.datos,
                                                                                movil: e.target.value
                                                                            },
                                                                            invalidos: invalidos
                                                                        })
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <label className="form-control-label"
                                                                       htmlFor="input-preferencia">
                                                                    {Locale.format('voluntariado.form.step.0.preferencia') + " "}
                                                                    <FontAwesomeIcon icon={FA.faQuestionCircle}
                                                                                     id="preferenciaAyuda"
                                                                                     data-placement="top"
                                                                                     style={{borderBottom: "1pt dotted"}}/>
                                                                    <UncontrolledTooltip delay={0} placement="top"
                                                                                         target="preferenciaAyuda">
                                                                        {Locale.format('voluntariado.form.step.0.preferencia.ayuda')}
                                                                    </UncontrolledTooltip>
                                                                </label>
                                                                <div className="custom-control custom-radio mb-2">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        checked={this.state.datos.preferencia === "Teléfono"}
                                                                        id="preferencia-telefono"
                                                                        name="input-preferencia"
                                                                        type="radio"
                                                                        onChange={() => {
                                                                            this.setState({
                                                                                datos: {
                                                                                    ...this.state.datos,
                                                                                    preferencia: "Teléfono"
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                           htmlFor="preferencia-telefono">
                                                                        {Locale.format('voluntariado.form.step.0.preferencia.op0')}
                                                                    </label>
                                                                </div>
                                                                <div className="custom-control custom-radio mb-3">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        checked={this.state.datos.preferencia === "Email"}
                                                                        id="preferencia-email"
                                                                        name="input-preferencia"
                                                                        type="radio"
                                                                        onChange={() => {
                                                                            this.setState({
                                                                                datos: {
                                                                                    ...this.state.datos,
                                                                                    preferencia: "Email"
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                           htmlFor="preferencia-email">
                                                                        {Locale.format('voluntariado.form.step.0.preferencia.op1')}
                                                                    </label>
                                                                </div>
                                                            </FormGroup>
                                                        </>;
                                                    case 1:
                                                        return <>
                                                            <Row>
                                                                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                                                    <FormGroup>
                                                                        <label className="form-control-label"
                                                                               htmlFor="input-dni">
                                                                            {Locale.format('voluntariado.form.step.1.dni')}
                                                                        </label>
                                                                        <Input
                                                                            value={this.state.datos.dni}
                                                                            className={"form-control" + (this.state.invalidos.indexOf('dni') > -1 ? " form-control-error" : "")}
                                                                            id="input-dni"
                                                                            placeholder={Locale.format('voluntariado.form.step.1.dni.pista')}
                                                                            type="text"
                                                                            onChange={e => {
                                                                                let invalidos = [...this.state.invalidos];
                                                                                const valido = e.target.value === "" || Validadores.dni(e.target.value);
                                                                                if (!valido && this.state.invalidos.indexOf("dni") === -1)
                                                                                    invalidos = [...this.state.invalidos, "dni"];
                                                                                else if (valido && this.state.invalidos.indexOf("dni") > -1)
                                                                                    invalidos.splice(this.state.invalidos.indexOf("dni"));
                                                                                this.setState({
                                                                                    datos: {
                                                                                        ...this.state.datos,
                                                                                        dni: e.target.value
                                                                                    },
                                                                                    invalidos: invalidos
                                                                                })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                                                    <FormGroup>
                                                                        <label className="form-control-label"
                                                                               htmlFor="input-fechaNacimiento">
                                                                            {Locale.format('voluntariado.form.step.1.fechaNacimiento') + " "}
                                                                        </label>
                                                                        <InputGroup className="input-group"
                                                                                    id="input-fechaNacimiento">
                                                                            <ReactDatetime
                                                                                inputProps={{
                                                                                    placeholder: Locale.format('voluntariado.form.step.1.fechaNacimiento.pista'),
                                                                                    readOnly: true
                                                                                }}
                                                                                timeFormat={false}
                                                                                value={moment(this.state.datos.fechaNacimiento)}
                                                                                onChange={(e: any) => {
                                                                                    if (typeof e === typeof "")
                                                                                        e = null;
                                                                                    if (e !== null)
                                                                                        this.setState({
                                                                                            datos: {
                                                                                                ...this.state.datos,
                                                                                                fechaNacimiento: e.format("YYYY-MM-DD")
                                                                                            }
                                                                                        });
                                                                                }}
                                                                            />
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <FormGroup>
                                                                <label className="form-control-label"
                                                                       htmlFor="input-telefono">
                                                                    {Locale.format('voluntariado.form.step.1.telefono')}
                                                                </label>
                                                                <Input
                                                                    value={this.state.datos.telefono}
                                                                    className={"form-control" + (this.state.invalidos.indexOf('telefono') > -1 ? " form-control-error" : "")}
                                                                    id="input-telefono"
                                                                    placeholder={Locale.format('voluntariado.form.step.1.telefono.pista')}
                                                                    type="text"
                                                                    onChange={e => {
                                                                        let invalidos = [...this.state.invalidos];
                                                                        const valido = e.target.value === "" || Validadores.telefono(e.target.value);
                                                                        if (!valido && this.state.invalidos.indexOf("telefono") === -1)
                                                                            invalidos = [...this.state.invalidos, "telefono"];
                                                                        else if (valido && this.state.invalidos.indexOf("telefono") > -1)
                                                                            invalidos.splice(this.state.invalidos.indexOf("telefono"));
                                                                        this.setState({
                                                                            datos: {
                                                                                ...this.state.datos,
                                                                                telefono: e.target.value
                                                                            },
                                                                            invalidos: invalidos
                                                                        })
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <label className="form-control-label"
                                                                       htmlFor="input-direccion">
                                                                    {Locale.format('voluntariado.form.step.1.direccion')}
                                                                </label>
                                                                <Input
                                                                    value={this.state.datos.direccion}
                                                                    className={"form-control"}
                                                                    id="input-direccion"
                                                                    placeholder={Locale.format('voluntariado.form.step.1.direccion.pista')}
                                                                    type="textarea"
                                                                    onChange={e => {
                                                                        this.setState({
                                                                            datos: {
                                                                                ...this.state.datos,
                                                                                direccion: e.target.value
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                            <hr/>
                                                            <FormGroup>
                                                                <label className="form-control-label"
                                                                       htmlFor="input-profesion">
                                                                    {Locale.format('voluntariado.form.step.1.profesion')}
                                                                </label>
                                                                <Input
                                                                    value={this.state.datos.profesion}
                                                                    className="form-control"
                                                                    id="input-profesion"
                                                                    placeholder={Locale.format('voluntariado.form.step.1.profesion.pista')}
                                                                    type="text"
                                                                    onChange={e => {
                                                                        this.setState({
                                                                            datos: {
                                                                                ...this.state.datos,
                                                                                profesion: e.target.value
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <label className="form-control-label"
                                                                       htmlFor="input-formacion">
                                                                    {Locale.format('voluntariado.form.step.1.formacion')}
                                                                </label>
                                                                <Input
                                                                    value={this.state.datos.formacion}
                                                                    className="form-control"
                                                                    id="input-formacion"
                                                                    placeholder={Locale.format('voluntariado.form.step.1.formacion.pista')}
                                                                    type="textarea"
                                                                    onChange={e => {
                                                                        this.setState({
                                                                            datos: {
                                                                                ...this.state.datos,
                                                                                formacion: e.target.value
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <label className="form-control-label"
                                                                       htmlFor="input-otros">
                                                                    {Locale.format('voluntariado.form.step.1.otros')}
                                                                </label>
                                                                <Input
                                                                    value={this.state.datos.otros}
                                                                    className="form-control"
                                                                    id="input-otros"
                                                                    placeholder={Locale.format('voluntariado.form.step.1.otros.pista')}
                                                                    type="textarea"
                                                                    onChange={e => {
                                                                        this.setState({
                                                                            datos: {
                                                                                ...this.state.datos,
                                                                                otros: e.target.value
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </>;
                                                    case 2:
                                                        return <>
                                                            <FormGroup className="mt-2">
                                                                <label className="form-control-label"
                                                                       htmlFor="input-aviso">
                                                                    {Locale.format('voluntariado.form.step.2.aviso')}
                                                                </label>
                                                                <br/>
                                                                <small>
                                                                    {Locale.format('voluntariado.form.step.2.aviso.legal') + " "}
                                                                    – <a
                                                                    href={"mailto:" + CONTACTO_EMAIL}>{CONTACTO_EMAIL}</a>).
                                                                </small>
                                                                <div className="custom-control custom-radio mt-3 mb-2">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        checked={this.state.datos.aviso === "Acepto"}
                                                                        id="aviso-acepto"
                                                                        name="input-aviso"
                                                                        type="radio"
                                                                        onChange={() => {
                                                                            this.setState({
                                                                                datos: {
                                                                                    ...this.state.datos,
                                                                                    aviso: "Acepto"
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                           htmlFor="aviso-acepto">
                                                                        {Locale.format('voluntariado.form.step.2.aviso.acepto')}
                                                                    </label>
                                                                </div>
                                                            </FormGroup>
                                                            <div className="text-center">
                                                                <div className="d-inline-block">
                                                                    <ReCAPTCHA
                                                                        hl={Locale.getLang()}
                                                                        theme="dark"
                                                                        sitekey={RECAPTCHA}
                                                                        onChange={value => this.setState({recaptcha: value !== null})}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>;
                                                    default:
                                                        return <></>;
                                                }
                                            })()}
                                        </Form>
                                        <Row className="mt-3">
                                            {this.state.step !== 0 ? <Col>
                                                <Button
                                                    outline
                                                    disabled={!this.validar(this.state.step - 1) || this.state.enviando || this.state.enviado}
                                                    color="warning"
                                                    type="button"
                                                    onClick={() => {
                                                        if (!this.validar(this.state.step - 1) || this.state.enviando || this.state.enviado)
                                                            return;
                                                        this.setState({step: this.state.step - 1})
                                                    }}
                                                >
                                                    {Locale.format('voluntariado.form.step.atras')}
                                                </Button>
                                            </Col> : <></>}
                                            <Col className="text-right">
                                                <Button
                                                    outline
                                                    disabled={!this.validar(this.state.step + 1) || this.state.enviando || this.state.enviado}
                                                    color="warning"
                                                    type="button"
                                                    onClick={() => {
                                                        if (!this.validar(this.state.step + 1) || this.state.enviando || this.state.enviado)
                                                            return;
                                                        if (this.state.step === 2) {
                                                            this.enviar();
                                                            return;
                                                        }
                                                        this.setState({step: this.state.step + 1})
                                                    }}
                                                >
                                                    {this.state.enviando ?
                                                        <FontAwesomeIcon icon={FA.faCircleNotch} spin/> :
                                                        (this.state.step === 2 ? Locale.format('voluntariado.form.step.enviar') : Locale.format('voluntariado.form.step.siguiente'))}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <nav aria-label="..." className="mt-3">
                                            <Pagination
                                                className="pagination justify-content-center pagination-lg"
                                                listClassName="justify-content-center pagination-lg"
                                            >
                                                <PaginationItem
                                                    className={this.state.step === 0 ? "active" : (!this.validar(0) || this.state.enviando ? "disabled" : "")}
                                                >
                                                    <PaginationLink
                                                        className={this.validar(0) && this.state.step === 0 ? "bg-warning" : ""}
                                                        style={{borderColor: this.validar(0) ? "var(--orange)" : ""}}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            if (this.state.enviado || this.state.enviando)
                                                                return;
                                                            this.setState({step: 0});
                                                        }}
                                                    >
                                                        1
                                                    </PaginationLink>
                                                </PaginationItem>
                                                <PaginationItem
                                                    className={this.state.step === 1 ? "active" : (!this.validar(1) || this.state.enviando ? "disabled" : "")}
                                                >
                                                    <PaginationLink
                                                        className={this.validar(1) && this.state.step === 1 ? "bg-warning" : ""}
                                                        style={{borderColor: this.validar(1) ? "var(--orange)" : ""}}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            if (!this.validar(1) || this.state.enviado || this.state.enviando)
                                                                return;
                                                            this.setState({step: 1});
                                                        }}
                                                    >
                                                        2
                                                    </PaginationLink>
                                                </PaginationItem>
                                                <PaginationItem
                                                    className={this.state.step === 2 ? "active" : (!this.validar(2) || this.state.enviando ? "disabled" : "")}
                                                >
                                                    <PaginationLink
                                                        className={this.validar(2) && this.state.step === 2 ? "bg-warning" : ""}
                                                        style={{borderColor: this.validar(2) ? "var(--orange)" : ""}}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            if (!this.validar(2) || this.state.enviado || this.state.enviando)
                                                                return;
                                                            this.setState({step: 2});
                                                        }}
                                                    >
                                                        3
                                                    </PaginationLink>
                                                </PaginationItem>
                                            </Pagination>
                                        </nav>
                                    </CardFooter>
                                </Card>
                            </Col>{*/}
                            <Col lg="2" className="d-none d-lg-block"/>
                        </Row>
                    </Container>
                </section>
            </div>
            <iframe
                className="d-none"
                ref={this.iframe}
                title="iframe_callback" id="iframe_callback" name="iframe_callback"
                onLoad={() => this.state.enviando ? this.setState({enviando: false, enviado: true}) : null}
            />
        </>;
    }

    private validar(s: number = 3): boolean {
        if (s === 0) {
        } else if (s === 1) {
            if (this.state.datos.nombre === "" || this.state.datos.apellidos === "" ||
                !Validadores.email(this.state.datos.email) || !Validadores.movil(this.state.datos.movil) || this.state.datos.preferencia === "")
                return false;
        } else if (s === 2) {
            if (!this.validar(1))
                return false;
        } else {
            if (!this.validar(2))
                return false;
            if (this.state.datos.aviso !== "Acepto" || !this.state.recaptcha)
                return false;
        }
        return true;
    }

    private enviar() {
        this.setState({enviando: true}, () => {
            let body: { [id: string]: string } = {};
            for (let key in this.valores) {
                let value = (this.valores as any)[key];
                if (key === "fechaNacimiento") {
                    let fecha = this.state.datos.fechaNacimiento.split("-");
                    body[value + "_year"] = fecha[0];
                    body[value + "_month"] = fecha[1];
                    body[value + "_day"] = fecha[2];
                } else if (key === "carnets") {
                    body[value + "_sentinel"] = "";
                    body[value] = (this.state.datos as any)[key];
                } else {
                    body[value] = (this.state.datos as any)[key];
                }
            }
            let form = document.createElement("form");
            form.method = "POST";
            form.action = this.formulario;
            form.className = "d-none";
            form.target = "iframe_callback";
            for (let key in body) {
                let value = body[key];
                let element = document.createElement("input");
                element.name = key;
                element.value = value.replace("\r\n", "\n").replace("\n", "\r\n");
                form.appendChild(element);
            }
            document.body.appendChild(form);

            form.submit();
        })
    }
}