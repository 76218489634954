import React from 'react';
import {Route,useHistory, useLocation, useRouteMatch} from 'react-router-dom';

import {AppRoutes, Locale} from "./i18n";
import {LocalizedSwitch} from "./i18n/comps";
import * as views from './views';
import withTracker from './data/tracker';

const locale = Locale.getLang();
if (locale !== "en")
    require('moment/locale/' + locale);


const App: React.FC = () => {
    let router = {
        history: useHistory(),
        location: useLocation(),
        match: useRouteMatch(),
    };

    return <LocalizedSwitch>
        <Route exact path={AppRoutes.Inicio} component={withTracker(views.Inicio, router)}/>
        {/* NOSOTROS */}
        <Route exact path={AppRoutes.Somos} component={withTracker(views.Somos, router)}/>
        <Route exact path={AppRoutes.Intervenciones} component={withTracker(views.Intervenciones, router)}/>
        <Route exact path={AppRoutes.Formacion} component={withTracker(views.Formacion, router)}/>
        <Route exact path={AppRoutes.Recursos} component={withTracker(views.Recursos, router)}/>
        {/* COMUNICACION */}
        <Route exact path={AppRoutes.Galeria} component={withTracker(views.Galeria, router)}/>
        <Route exact path={AppRoutes.Memorias} component={withTracker(views.Memorias, router)}/>
        <Route exact path={AppRoutes.Enlaces} component={withTracker(views.Enlaces, router)}/>
        {/* OTROS */}
        <Route exact path={AppRoutes.Agradecimientos} component={withTracker(views.Agradecimientos, router)}/>
        <Route exact path={AppRoutes.Contacto} component={withTracker(views.Contacto, router)}/>
        <Route exact path={AppRoutes.Voluntariado} component={withTracker(views.Voluntariado, router)}/>
        <Route path={"*"} component={withTracker(views.Error404, router)}/>
    </LocalizedSwitch>;
};

export default App;