import gl from "./gl.json";
import es from "./es.json";
import en from "./en.json";

export const strings = {
    'gl': gl,
    'es': es,
    'en': en
};

export default strings;