import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import cx from "../../assets";

interface ContactProps {

}

interface ContactState {
    nameFocused: boolean;
    emailFocused: boolean;
}

export class Contact extends React.Component<ContactProps, ContactState> {
    constructor(props: ContactProps) {
        super(props);
        this.state = {
            nameFocused: false,
            emailFocused: false
        }
    }

    render() {
        return <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
                <Row className="justify-content-center mt--300">
                    <Col lg="8">
                        <Card className="bg-gradient-secondary shadow">
                            <CardBody className="p-lg-5">
                                <h4 className="mb-1">Envíanos un mensaje</h4>
                                <p className="mt-0">
                                    Si tienes cualquier duda o pregunta no dudes en mandarnos un email.
                                </p>
                                <FormGroup className={cx({
                                    'focused': this.state.nameFocused
                                })}>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={FA.faQuestionCircle}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Asunto"
                                            type="text"
                                            onFocus={e => this.setState({nameFocused: true})}
                                            onBlur={e => this.setState({nameFocused: false})}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className="mb-4">
                                    <Input
                                        className="form-control-alternative"
                                        cols="80"
                                        name="name"
                                        placeholder="Mensaje..."
                                        rows="4"
                                        type="textarea"
                                    />
                                </FormGroup>
                                <div>
                                    <Button
                                        block
                                        className="btn-round"
                                        color="default"
                                        size="lg"
                                        type="button"
                                    >
                                        Enviar
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>;
    }
}