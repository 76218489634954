import React from "react";
import {Helmet} from "react-helmet";
import {AppRoutes, Locale, Route} from "../../i18n";
import {Badge, Button, Card, CardImg, Col, Container, Row, UncontrolledCarousel} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

interface RecursosProps {
}

interface RecursosState {
}

export class Recursos extends React.Component<RecursosProps, RecursosState> {
    private items = [
        {src: require("../../assets/img/nosotros/recursos/s3.jpg"), altText: "", caption: "", header: ""},
        {src: "https://proteccioncivil.blob.core.windows.net/santiago/8b81f31a-485a-465c-8423-bf61abcfa84a/1590150362104.png", altText: "", caption: "", header: ""},
        {src: "https://proteccioncivil.blob.core.windows.net/santiago/49a0676e-1a2f-4228-b44d-ed989f348c2f/1590147402133.png", altText: "", caption: "", header: ""},
        {src: "https://proteccioncivil.blob.core.windows.net/santiago/42e45014-320e-400a-9293-e32ef4f2afb4/1590146023082.png", altText: "", caption: "", header: ""},
    ];

    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("nosotros.recursos.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-indigo">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6" className="order-lg-2">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('nosotros.recursos.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('nosotros.recursos.subtitular')}
                                    </p>
                                </Col>
                                <Col lg="6" className="order-lg-1">
                                    <img
                                        alt="..." className="img-fluid rounded shadow"
                                        src={require('../../assets/img/nosotros/recursos/p1.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                                <div className="pr-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-indigo shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faBuilding}/>
                                    </div>
                                    <h3>{Locale.format("nosotros.recursos.base.titulo")}</h3>
                                    <p className="lead">{Locale.format("nosotros.recursos.base.subtitulo")}</p>
                                    <p>{Locale.format("nosotros.recursos.base.p1")}</p>
                                    <p>{Locale.format("nosotros.recursos.base.p2")}</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <img alt="..." className="img-fluid shadow shadow-lg--hover"
                                     src={require('../../assets/img/nosotros/recursos/s1.jpg')}/>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                                <Card className="bg-indigo shadow border-0 floating">
                                    <CardImg alt="..." src={require("../../assets/img/nosotros/recursos/s2.jpg")} top/>
                                    <blockquote className="card-blockquote">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="svg-bg"
                                            preserveAspectRatio="none"
                                            viewBox="0 0 583 95"
                                        >
                                            <polygon className="fill-indigo" points="0,52 583,95 0,95"/>
                                            <polygon className="fill-indigo" opacity=".2"
                                                     points="0,42 583,95 683,0 0,95"/>
                                        </svg>
                                    </blockquote>
                                </Card>
                            </Col>
                            <Col md="6">
                                <div className="pl-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-indigo shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faUsers}/>
                                    </div>
                                    <h3>{Locale.format("nosotros.recursos.voluntarios.titulo")}</h3>
                                    <p className="lead">{Locale.format("nosotros.recursos.voluntarios.subtitulo")}</p>
                                    <p>{Locale.format("nosotros.recursos.voluntarios.p1")}</p>
                                    <p>{Locale.format("nosotros.recursos.voluntarios.p2")}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                                <div className="icon icon-lg icon-shape icon-shape-indigo shadow rounded-circle mb-5">
                                    <FontAwesomeIcon icon={FA.faNetworkWired}/>
                                </div>
                                <h3>{Locale.format("nosotros.recursos.vehiculos.titulo")}</h3>
                                <p className="lead">{Locale.format("nosotros.recursos.vehiculos.subtitulo")}</p>
                                <ul className="list-unstyled mt-5">
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="indigo">
                                                    <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">
                                                    {Locale.format("nosotros.recursos.vehiculos.l1")}
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="indigo">
                                                    <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">
                                                    {Locale.format("nosotros.recursos.vehiculos.l2")}
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="indigo">
                                                    <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">
                                                    {Locale.format("nosotros.recursos.vehiculos.l3")}
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="indigo">
                                                    <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">
                                                    {Locale.format("nosotros.recursos.vehiculos.l4")}
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Badge className="badge-circle mr-3" color="indigo">
                                                    <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                </Badge>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">
                                                    {Locale.format("nosotros.recursos.vehiculos.l5")}
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                            <Col md="6">
                                <UncontrolledCarousel items={this.items}/>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section bg-secondary section-lg">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col>
                                <Card className="bg-gradient-indigo shadow-lg border-0">
                                    <div className="p-5">
                                        <Row className="align-items-center">
                                            <Col lg="9">
                                                <h3 className="text-white">{Locale.format("nosotros.recursos.otros.titulo")}</h3>
                                                <p className="lead text-white mt-3">
                                                    {Locale.format("nosotros.recursos.otros.descripcion")}
                                                </p>
                                            </Col>
                                            <Col className="ml-lg-auto" lg="3">
                                                <Button
                                                    block
                                                    className="btn-white"
                                                    color="default"
                                                    to={Route(AppRoutes.Voluntariado)}
                                                    size="lg"
                                                    tag={Link}
                                                >
                                                    {Locale.format("nosotros.recursos.otros.boton")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>;
    }
}