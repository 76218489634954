import React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {
    Button,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Media,
    Nav,
    Navbar as NavbarReact,
    NavbarBrand,
    NavItem,
    Row,
    UncontrolledCollapse,
    UncontrolledDropdown,
} from "reactstrap";
import Headroom from "headroom.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {AppLanguages, AppRoutes, appStrings, DefaultLanguage, Locale, Route} from "../../i18n";

import cx from '../../assets';

interface NavbarProps extends RouteComponentProps {

}

interface NavbarState {
    collapseClasses: string;
    nav: boolean;
}

export class Navbar extends React.Component<NavbarProps, NavbarState> {
    constructor(props: NavbarProps) {
        super(props);
        this.state = {
            collapseClasses: "",
            nav: false
        }
    }

    componentDidMount(): void {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        headroom.init();
    }

    componentDidUpdate(prevProps: Readonly<NavbarProps>, prevState: Readonly<NavbarState>, snapshot?: any): void {
        if (prevProps.location.pathname !== this.props.location.pathname && this.state.nav) {
            // TODO: Prevenir cerrar navbar
        }
    }

    render() {
        return <>
            <header className={cx({'header-global': true})}>
                <NavbarReact
                    className={cx({
                        'navbar-transparent': true,
                        'navbar-light': true,
                        'headroom': true
                    })}
                    expand="xl"
                    id="navbar-main"
                >
                    <Container>
                        <NavbarBrand className="mr-lg-5" to={Route(AppRoutes.Inicio)} tag={Link}>
                            <img
                                style={{filter: "brightness(0) invert(1)"}}
                                alt="..."
                                src={require('../../assets/img/pcivil-brand.png')}
                            />
                        </NavbarBrand>
                        <button className="navbar-toggler" id="navbar_global">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <UncontrolledCollapse
                            toggler="#navbar_global" navbar
                            className={this.state.collapseClasses}
                            onExiting={() => this.setState({collapseClasses: "collapsing-out"})}
                            onExited={() => this.setState({collapseClasses: ""})}
                        >
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <Link to={Route(AppRoutes.Inicio)}>
                                            <img alt="..." src={require('../../assets/img/pcivil-navbar.png')}/>
                                        </Link>
                                    </Col>
                                    <Col className="collapse-close" xs="6">
                                        <button className="navbar-toggler" id="navbar_global">
                                            <span/>
                                            <span/>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <FontAwesomeIcon icon={FA.faUsers}/>
                                        <span className="nav-link-inner--text">{Locale.format("nav.nosotros")}</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-xl">
                                        <div className="dropdown-menu-inner">
                                            <Media
                                                className="d-flex align-items-center" tag={Link}
                                                to={Route(AppRoutes.Somos)}
                                            >
                                                <div
                                                    className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faShieldAlt}/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-warning mb-md-1">
                                                        {Locale.format("nav.nosotros.somos")}
                                                    </h6>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {Locale.format("nav.nosotros.somos.descripcion")}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media
                                                className="d-flex align-items-center"
                                                to={Route(AppRoutes.Intervenciones)}
                                                tag={Link}
                                            >
                                                <div
                                                    className="icon icon-shape bg-gradient-danger rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faSign}/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-danger mb-md-1">
                                                        {Locale.format("nav.nosotros.intervenciones")}
                                                    </h6>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {Locale.format("nav.nosotros.intervenciones.descripcion")}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media
                                                className="d-flex align-items-center"
                                                to={Route(AppRoutes.Formacion)}
                                                tag={Link}
                                            >
                                                <div
                                                    className="icon icon-shape bg-gradient-info rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faChalkboardTeacher}/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-info mb-md-1">
                                                        {Locale.format("nav.nosotros.formacion")}
                                                    </h6>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {Locale.format("nav.nosotros.formacion.descripcion")}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media
                                                className="d-flex align-items-center"
                                                to={Route(AppRoutes.Recursos)}
                                                tag={Link}
                                            >
                                                <div
                                                    className="icon icon-shape bg-gradient-indigo rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faNetworkWired}/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-indigo mb-md-1">
                                                        {Locale.format("nav.nosotros.recursos")}
                                                    </h6>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {Locale.format("nav.nosotros.recursos.descripcion")}
                                                    </p>
                                                </Media>
                                            </Media>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <FontAwesomeIcon icon={FA.faBullhorn}/>
                                        <span className="nav-link-inner--text">{Locale.format("nav.comunicacion")}</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-xl">
                                        <div className="dropdown-menu-inner">
                                            <Media
                                                className="d-flex align-items-center"
                                                to={Route(AppRoutes.Galeria)}
                                                tag={Link}
                                            >
                                                <div
                                                    className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faPhotoVideo}/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-primary mb-md-1">
                                                        {Locale.format("nav.comunicacion.galeria")}
                                                    </h6>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {Locale.format("nav.comunicacion.galeria.descripcion")}
                                                    </p>
                                                </Media>
                                            </Media>
                                            {/*}
                                            <Media
                                                className="d-flex align-items-center"
                                                to={Route(AppRoutes.Inicio)}
                                                tag={Link}
                                            >
                                                <div
                                                    className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faNewspaper}/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-warning mb-md-1">
                                                        {Locale.format("nav.comunicacion.noticias")}
                                                    </h6>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {Locale.format("nav.comunicacion.noticias.descripcion")}
                                                    </p>
                                                </Media>
                                            </Media>
                                            {*/}
                                            <Media
                                                className="d-flex align-items-center"
                                                to={Route(AppRoutes.Memorias)}
                                                tag={Link}
                                            >
                                                <div
                                                    className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faBook}/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-success mb-md-1">
                                                        {Locale.format("nav.comunicacion.memorias")}
                                                    </h6>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {Locale.format("nav.comunicacion.memorias.descripcion")}
                                                    </p>
                                                </Media>
                                            </Media>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <FontAwesomeIcon icon={FA.faQuestionCircle}/>
                                        <span className="nav-link-inner--text">{Locale.format("nav.contacto")}</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-xl">
                                        <div className="dropdown-menu-inner">
                                            <Media
                                                className="d-flex align-items-center"
                                                to={Route(AppRoutes.Contacto)}
                                                tag={Link}
                                            >
                                                <div
                                                    className="icon icon-shape bg-gradient-gray-dark rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faSign}/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-gray-dark mb-md-1">
                                                        {Locale.format("nav.contacto.contacto")}
                                                    </h6>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {Locale.format("nav.contacto.contacto.descripcion")}
                                                    </p>
                                                </Media>
                                            </Media>
                                            <Media
                                                className="d-flex align-items-center"
                                                to={Route(AppRoutes.Enlaces)}
                                                tag={Link}
                                            >
                                                <div
                                                    className="icon icon-shape bg-gradient-gray rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faHandshake}/>
                                                </div>
                                                <Media body className="ml-3">
                                                    <h6 className="heading text-gray mb-md-1">
                                                        {Locale.format("nav.comunicacion.enlaces")}
                                                    </h6>
                                                    <p className="description d-none d-md-inline-block mb-0">
                                                        {Locale.format("nav.comunicacion.enlaces.descripcion")}
                                                    </p>
                                                </Media>
                                            </Media>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                            <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav>
                                        <FontAwesomeIcon icon={FA.faGlobe}/>
                                        <span className="nav-link-inner--text">{Locale.format('lang')}</span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {AppLanguages.map(lang => (
                                            <DropdownItem
                                                to={this.getMatchingRoute(lang)}
                                                tag={Link}
                                                className={cx({"active": Locale.getLang() === lang})}
                                                key={lang}
                                                onClick={() => Locale.changeLang(lang)}
                                                style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}
                                            >
                                                {Locale.format("lang." + lang)}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem className="ml-lg-4">
                                    <Button
                                        className="btn-outline-warning btn-icon"
                                        to={Route(AppRoutes.Voluntariado)}
                                        tag={Link}
                                    >
                                        <span className="btn-inner--icon">
                                            <FontAwesomeIcon icon={FA.faUserPlus}/>
                                        </span>
                                        <span className="nav-link-inner--text ml-1">
                                            {Locale.format('nav.voluntariado')}
                                        </span>
                                    </Button>
                                </NavItem>
                            </Nav>
                        </UncontrolledCollapse>
                    </Container>
                </NavbarReact>
            </header>
        </>;
    }

    private getMatchingRoute(language: string) {
        const locale = Locale.getLang();

        const route = DefaultLanguage === locale ? this.props.location.pathname : this.props.location.pathname.replace(`/${locale}`, ``);
        const routeKey = Object.keys((appStrings as any)[locale]).find(key => (appStrings as any)[locale][key] === route) as string;

        const matchingRoute = (appStrings as any)[language][routeKey];

        if (matchingRoute === undefined || routeKey === undefined)
            return language === DefaultLanguage ? route : `/${language}` + route;
        else
            return language === DefaultLanguage ? matchingRoute : `/${language}` + matchingRoute;
    }
}