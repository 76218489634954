const AZURE_ACCOUNT = "proteccioncivil";
const AZURE_CONTAINER = "web";

export const url = (blob: string) => {
    if (!blob.startsWith("/")) {
        blob = "/" + blob;
    }

    return "https://" + AZURE_ACCOUNT + ".blob.core.windows.net/" + AZURE_CONTAINER + blob;
}

interface Memorias {
    [key: string]: {
        full: string
    }
}

export const memorias: Memorias = {
    "2015": {
        full: "/70961b08-be8f-44df-b2ba-8d7eaf79cbfb/2015.pdf"
    },
    "2016": {
        full: "/304c365b-dec6-471e-bc8a-0bb01b930775/2016.pdf"
    },
    "2017": {
        full: '/6b225467-c3c1-42d7-aa3c-3b1486016929/2017.pdf'
    }
};