import React from "react";
import {Helmet} from "react-helmet";
import {Locale} from "../../i18n";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";

interface EnlacesProps {
}

interface EnlacesState {
}

export class Enlaces extends React.Component<EnlacesProps, EnlacesState> {
    private concello = "Pazo de Raxoi - Praza do Obradoiro, 15705 Santiago de Compostela (A Coruña)";

    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("comunicacion.enlaces.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-gray">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('comunicacion.enlaces.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('comunicacion.enlaces.subtitular')}
                                    </p>
                                </Col>
                                <Col lg="5">
                                    <img
                                        alt="..." className="img-fluid rounded shadow"
                                        src={require('../../assets/img/comunicacion/enlaces/p1.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section section-sm">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                <Card className="bg-gradient-indigo shadow-lg border-0">
                                    <CardBody>
                                        <p className="lead text-white mt-3">
                                            {Locale.format("comunicacion.enlaces.concello")}
                                        </p>
                                        <p className="text-white mt-3">
                                            <a
                                                className="text-white"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="http://santiagodecompostela.gal"
                                            >
                                                <FontAwesomeIcon icon={FA.faGlobe}/> santiagodecompostela.gal
                                            </a>
                                        </p>
                                        <p className="text-white mt-3">
                                            <a
                                                className="text-white"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={"http://maps.google.com/?q=" + encodeURI(this.concello)}
                                            >
                                                <FontAwesomeIcon icon={FA.faBuilding}/> {this.concello}
                                            </a>
                                        </p>
                                        <p className="text-white mt-3">
                                            <a
                                                className="text-white"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="tel:010"
                                            >
                                                <FontAwesomeIcon icon={FA.faUniversalAccess}/>
                                                {" 010 " + Locale.format("comunicacion.enlaces.concello.telefono")}
                                            </a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="8" xl="8">
                                <Row className="row-grid align-items-center">
                                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                        <Card className="bg-gradient-warning shadow-lg border-0">
                                            <CardBody>
                                                <h3 className="text-white mt-3">
                                                    {Locale.format("comunicacion.enlaces.emergencias")}
                                                </h3>
                                                <p className="lead text-white mt-3">
                                                    <a
                                                        className="text-white"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="tel:112"
                                                    >
                                                        <FontAwesomeIcon icon={FA.faHeadset}/> 112
                                                    </a>
                                                </p>
                                                <p className="text-white mt-3">
                                                    <a
                                                        className="text-white"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="https://www.axega112.gal"
                                                    >
                                                        <FontAwesomeIcon icon={FA.faGlobe}/> axega112.gal
                                                    </a>
                                                </p>
                                                <p className="text-white mt-3">
                                                    <a
                                                        className="text-white"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="tel:061"
                                                    >
                                                        <FontAwesomeIcon icon={FA.faAmbulance}/>
                                                        {" 061 " + Locale.format("comunicacion.enlaces.sanitarios")}
                                                    </a>
                                                </p>
                                                <p className="text-white mt-3">
                                                    <a
                                                        className="text-white"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="tel:080"
                                                    >
                                                        <FontAwesomeIcon icon={FA.faFireExtinguisher}/>
                                                        {" 080 " + Locale.format("comunicacion.enlaces.bomberos")}
                                                    </a>
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                        <Card className="bg-gradient-default shadow-lg border-0">
                                            <CardBody>
                                                <h3 className="text-white mt-3">
                                                    {Locale.format("comunicacion.enlaces.policia")}
                                                </h3>
                                                <p className="lead text-white mt-3">
                                                    <a
                                                        className="text-white"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="tel:092"
                                                    >
                                                        <FontAwesomeIcon icon={FA.faShieldAlt}/>
                                                        {" 092 " + Locale.format("comunicacion.enlaces.policia.local")}
                                                    </a>
                                                </p>
                                                <p className="text-white mt-3">
                                                    <a
                                                        className="text-white"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="tel:091"
                                                    >
                                                        <FontAwesomeIcon icon={FA.faPassport}/>
                                                        {" 091 " + Locale.format("comunicacion.enlaces.policia.nacional")}
                                                    </a>
                                                </p>
                                                <p className="text-white mt-3">
                                                    <a
                                                        className="text-white"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="tel:062"
                                                    >
                                                        <FontAwesomeIcon icon={FA.faRoad}/>
                                                        {" 062 " + Locale.format("comunicacion.enlaces.policia.gc")}
                                                    </a>
                                                </p>
                                                <p className="text-white mt-3">
                                                    <a
                                                        className="text-white"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="tel:088"
                                                    >
                                                        <FontAwesomeIcon icon={FA.faUniversity}/>
                                                        {" 088 " + Locale.format("comunicacion.enlaces.policia.autonomica")}
                                                    </a>
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>;
    }
}