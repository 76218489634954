import React from "react";
import {Helmet} from "react-helmet";
import {AppRoutes, Locale, Route} from "../../i18n";
import {Badge, Button, Card, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";

interface IntervencionesProps {
}

interface IntervencionesState {
}

export class Intervenciones extends React.Component<IntervencionesProps, IntervencionesState> {
    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("nosotros.intervenciones.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-danger">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6" className="order-lg-2">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('nosotros.intervenciones.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('nosotros.intervenciones.subtitular')}
                                    </p>
                                </Col>
                                <Col lg="5" className="order-lg-1">
                                    <img
                                        alt="..." className="img-fluid rounded shadow"
                                        src={require('../../assets/img/nosotros/intervenciones/p1.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section section-sm">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2 d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" md="5">
                                <img alt="..." className="img-fluid floating" src={require('../../assets/img/nosotros/intervenciones/s1.jpg')}/>
                            </Col>
                            <Col md="1" className="d-xs-none d-sm-none d-md-block d-lg-block d-xl-block"/>
                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faRoad}/>
                                    </div>
                                    <h3>{Locale.format("nosotros.intervenciones.caminos.titulo")}</h3>
                                    <p>{Locale.format("nosotros.intervenciones.caminos.subtitulo")}</p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.caminos.l1")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.caminos.l2")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.caminos.l3")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.caminos.l4")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faTrafficLight}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.caminos.l5")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section bg-secondary">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-1 d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" md="6">
                                <img alt="..." className="img-fluid floating" src={require('../../assets/img/nosotros/intervenciones/s2.jpg')}/>
                            </Col>
                            <Col className="order-md-2" md="6">
                                <div className="pr-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faPeopleCarry}/>
                                    </div>
                                    <h3>{Locale.format("nosotros.intervenciones.axuda.titulo")}</h3>
                                    <p>{Locale.format("nosotros.intervenciones.axuda.subtitulo")}</p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faHandsHelping}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.axuda.l1")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faHandsHelping}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.axuda.l2")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faHandsHelping}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.axuda.l3")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faHandsHelping}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.axuda.l4")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faHandsHelping}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.axuda.l5")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section section-sm">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2 d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" md="6">
                                <img alt="..." className="img-fluid floating" src={require('../../assets/img/nosotros/intervenciones/s3.jpg')}/>
                            </Col>
                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faUserShield}/>
                                    </div>
                                    <h3>{Locale.format("nosotros.intervenciones.otros.titulo")}</h3>
                                    <p>{Locale.format("nosotros.intervenciones.otros.subtitulo")}</p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faShieldAlt}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.otros.l1")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faShieldAlt}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.otros.l2")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faShieldAlt}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.otros.l3")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section bg-secondary">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-1 d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" md="6">
                                <img alt="..." className="img-fluid floating" src={require('../../assets/img/nosotros/intervenciones/s4.jpg')}/>
                            </Col>
                            <Col className="order-md-2" md="6">
                                <div className="pr-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-danger shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faStreetView}/>
                                    </div>
                                    <h3>{Locale.format("nosotros.intervenciones.eventos.titulo")}</h3>
                                    <p>{Locale.format("nosotros.intervenciones.eventos.subtitulo")}</p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faUsers}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.eventos.l1")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faUsers}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.eventos.l2")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faUsers}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.eventos.l3")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faUsers}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.eventos.l4")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="danger">
                                                        <FontAwesomeIcon icon={FA.faUsers}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.intervenciones.eventos.l5")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section bg-secondary section-lg">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-1" md="8">
                                <Card className="bg-gradient-danger shadow-lg border-0">
                                    <div className="p-5">
                                        <Row className="align-items-center">
                                            <Col lg="9">
                                                <h3 className="text-white">{Locale.format("nosotros.intervenciones.interes.titulo")}</h3>
                                                <p className="lead text-white mt-3">
                                                    {Locale.format("nosotros.intervenciones.interes.descripcion")}
                                                </p>
                                            </Col>
                                            <Col className="ml-lg-auto" lg="3">
                                                <Button
                                                    block
                                                    className="btn-white"
                                                    color="default"
                                                    to={Route(AppRoutes.Voluntariado)}
                                                    size="lg"
                                                    tag={Link}
                                                >
                                                    {Locale.format("nosotros.intervenciones.interes.boton")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col className="order-md-2 d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" md="4">
                                <img alt="..." className="img-fluid shadow" src={require('../../assets/img/nosotros/intervenciones/s5.jpg')}/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>;
    }
}