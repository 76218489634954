export function shuffle(a: any) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export class Validadores {
    static dni(dni: string): boolean {
        const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
        const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
        const str = dni.toString().toUpperCase();

        if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

        const nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2');

        const letter = str.substr(-1);
        const charIndex = parseInt(nie.substr(0, 8)) % 23;

        return validChars.charAt(charIndex) === letter;
    }

    static email(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static movil(movil: string): boolean {
        const re = /^[0-9]\d+$/;
        if (movil.length !== 9) return false;
        if (movil.charAt(0) !== '6' && movil.charAt(0) !== '7') return false;
        return re.test(String(movil));
    }

    static telefono(telefono: string): boolean {
        if (telefono === "") return true;
        const re = /^[0-9]+$/;
        if (telefono.length !== 9) return false;
        if (telefono.charAt(0) !== '9' && telefono.charAt(0) !== '8') return false;
        return re.test(String(telefono));
    }
}