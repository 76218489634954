import React from "react";
import {Helmet} from "react-helmet";
import {Locale} from "../i18n";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {CONTACTO_DIRECCION, CONTACTO_EMAIL, CONTACTO_TELEFONO} from "../data/links";

interface ContactoProps {
}

interface ContactoState {
    asunto: string;
    cuerpo: string;
}

export class Contacto extends React.Component<ContactoProps, ContactoState> {
    constructor(props: ContactoProps) {
        super(props);
        this.state = {
            asunto: "",
            cuerpo: ""
        };
    }

    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("contacto.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped"
                         style={{paddingTop: 0, paddingBottom: 0}}>
                    <div className="shape shape-style-1 bg-gradient-gray-dark">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('contacto.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('contacto.subtitular')}
                                    </p>
                                </Col>
                                <Col lg="5">
                                    <img
                                        alt="..." className="img-fluid rounded shadow"
                                        src={require('../assets/img/contacto/p1.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                {/* 1st Hero Variation */}
                <section className="section section-sm">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col lg="4">
                                <Card className="bg-gradient-warning shadow-lg border-0">
                                    <CardBody>
                                        <div className="py-1">
                                            <p className="lead mt-3">
                                                <a href={"tel:" + CONTACTO_TELEFONO} className="text-white">
                                                    <FontAwesomeIcon icon={FA.faPhone}/> {CONTACTO_TELEFONO}
                                                </a>
                                            </p>
                                            <p className="lead mt-3">
                                                <a href={"mailto:" + CONTACTO_EMAIL} className="text-white">
                                                    <FontAwesomeIcon icon={FA.faAt}/> {CONTACTO_EMAIL}
                                                </a>
                                            </p>
                                            <p className="text-white mt-3">
                                                <a
                                                    className="text-white"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={"http://maps.google.com/?q=" + encodeURI(CONTACTO_DIRECCION)}
                                                >
                                                    <FontAwesomeIcon icon={FA.faBuilding}/> {CONTACTO_DIRECCION}
                                                </a>
                                            </p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="8">
                                <Card className="shadow">
                                    <CardHeader>
                                        {Locale.format('contacto.form')}
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-asunto">
                                                    {Locale.format('contacto.form.asunto')}
                                                </label>
                                                <InputGroup className="mb-4">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <FontAwesomeIcon icon={FA.faQuestionCircle}/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        value={this.state.asunto}
                                                        className="form-control"
                                                        id="input-asunto"
                                                        placeholder={Locale.format('contacto.form.asunto.pista')}
                                                        type="text"
                                                        onChange={e => this.setState({asunto: e.target.value})}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-cuerpo">
                                                    {Locale.format('contacto.form.cuerpo')}
                                                </label>
                                                <Input
                                                    value={this.state.cuerpo}
                                                    rows="4"
                                                    className="form-control"
                                                    id="input-cuerpo"
                                                    placeholder={Locale.format('contacto.form.cuerpo.pista')}
                                                    type="textarea"
                                                    onChange={e => this.setState({cuerpo: e.target.value})}
                                                />
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Button
                                            outline
                                            disabled={this.state.asunto === "" || this.state.cuerpo === ""}
                                            color="warning"
                                            type="button"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (this.state.asunto === "" || this.state.cuerpo === "")
                                                    return;
                                                let a = document.createElement("a");
                                                a.href = "mailto:" + CONTACTO_EMAIL + "?subject=" + encodeURI(this.state.asunto) + "&body=" + encodeURI(this.state.cuerpo);
                                                a.target = "_blank";
                                                document.body.appendChild(a);
                                                a.click();
                                                a.remove();
                                            }}
                                        >
                                            {Locale.format('voluntariado.form.step.enviar')}
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>;
    }
}