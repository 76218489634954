export enum AppRoutes {
    Inicio = 'rutas.inicio',

    Somos = 'rutas.somos',
    Intervenciones = 'rutas.intervenciones',
    Formacion = 'rutas.formacion',
    Recursos = 'rutas.recursos',

    Galeria = 'rutas.galeria',
    Memorias = 'rutas.memorias',
    Enlaces = 'rutas.enlaces',

    Voluntariado = 'rutas.voluntariado',
    Contacto = 'rutas.contacto',
    Agradecimientos = 'rutas.agradecimientos',
}
