import React from 'react';
import {RouteProps, Switch} from 'react-router';
import {AppLanguages, DefaultLanguage, Locale} from '../';


interface LocalizedSwitchProps {
}

interface LocalizedSwitchState {
}

export class LocalizedSwitch extends React.Component<LocalizedSwitchProps, LocalizedSwitchState> {
    render() {
        return <Switch>{
            React.Children.map(this.props.children, child => React.isValidElement<RouteProps>(child) && child.props.path !== "*" ?
                React.cloneElement(child, {
                    ...child.props,
                    path: Array.from(AppLanguages.map(lang => this.localizeRoutePath(lang, child.props.path)))
                }) :
                child)
        }</Switch>;
    }

    private localizeRoutePath(locale: string, path?: string | string[]) {
        switch (typeof path) {
            case 'undefined':
                return undefined;
            case 'object':
                return path.map(key => `/${locale}` + Locale.format(key));
            default:
                const isDefaultLang = locale === DefaultLanguage;
                return isDefaultLang
                    ? Locale.format(path, locale)
                    : `/${locale}` + Locale.format(path, locale);
        }
    }
}
