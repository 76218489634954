import React from 'react';
import {BrowserRouter, Redirect, Route} from 'react-router-dom';
import {AppLanguage, AppLanguages, DefaultLanguage} from '../';

import Cookies from "universal-cookie";

interface LocalizedRouterProps {
}

interface LocalizedRouterState {
}

export class LocalizedRouter extends React.Component<LocalizedRouterProps, LocalizedRouterState> {
    render() {
        return <BrowserRouter>
            <Route path="/:locale([a-z]{2,3})">
                {({match, location}) => {
                    const params = match ? match.params : {};
                    const {locale = DefaultLanguage || AppLanguage.Galician} = params;

                    // Current language
                    const lang = AppLanguages.includes(locale) ? locale : AppLanguage.Galician;
                    // Current path, including language
                    const {pathname} = location;
                    // Current path, without language code
                    const path = DefaultLanguage === lang ? pathname : pathname.replace(`/${lang}`, ``);

                    const cookies = new Cookies();
                    if (cookies.get("l10n") === undefined)
                        cookies.set('l10n', lang, {path: '/'});
                    // Current language in cookies
                    const clang = cookies.get("l10n");
                    if (cookies.get("i18n") === undefined)
                        cookies.set('i18n', "0", {path: '/'});
                    const credir = cookies.get("i18n");

                    if (credir === "0") {
                        fetch('https://ipapi.co/json/').then(res => res.json()).then((response) => {
                            let region = response.region_code;
                            let country = response.country_code;
                            cookies.set('i18n', "1", {path: '/'});
                            if (region === "GA" && country === "ES")
                                return <Redirect to={`/${path}`}/>;
                            else if (country === "ES")
                                return <Redirect to={`/es${path}`}/>;
                            else
                                return <Redirect to={`/en${path}`}/>;
                        }).catch((error) => {
                            console.warn("Error detectando el idioma");
                        });
                    }

                    /**
                     * Cookie language does not match path language, only in global root
                     * Lang = old
                     * Clang = new
                     */
                    if ((path === "/" || path === "") && lang === DefaultLanguage && lang !== clang)
                        return <Redirect to={`/${clang}${path}`}/>;
                    else if (lang !== DefaultLanguage && lang !== clang)
                        cookies.set('l10n', lang, {path: '/'});

                    /**
                     * If default language and in path, remove it
                     */
                    if (locale === DefaultLanguage && pathname.startsWith(`/${lang}`))
                        return (<Redirect to={path}/>);

                    /**
                     * If language is not in route path, redirect to language root.
                     * If not, return Intl provider with default language set
                     */
                    return this.props.children;
                }}
            </Route>
        </BrowserRouter>
    }
}
