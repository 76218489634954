import strings from './locales';
import {AppLanguages, DefaultLanguage} from "./app-languges";
import Cookies from "universal-cookie";

export class Locale {
    static getLang(): string {
        const lang = new Cookies().get('l10n');
        if (lang === undefined || lang === null || !AppLanguages.includes(lang)) {
            Locale.changeLang(DefaultLanguage);
            return Locale.getLang();
        }
        return lang;
    }

    static changeLang(lang: string) {
        if (lang !== "en")
            require('moment/locale/' + lang);
        new Cookies().set('l10n', lang, {path: '/'});
    }

    static format(s: string, locale: string = Locale.getLang()) {
        let string = (appStrings as any)[locale][s];
        if (string === undefined || string === null) {
            console.warn("SNF: " + JSON.stringify({
                id: s,
                locale: locale
            }));
            string = (appStrings as any)[DefaultLanguage][s];
        }
        return string || s;
    }
}

export const appStrings = strings;

export * from './app-languges';
export * from './app-routes';

export * from './comps';