import React from "react";
import {Helmet} from "react-helmet";
import {Locale} from "../i18n";
import {Col, Container, Row,} from "reactstrap";

interface AgradecimientosProps {
}

interface AgradecimientosState {
}

export class Agradecimientos extends React.Component<AgradecimientosProps, AgradecimientosState> {
    constructor(props: AgradecimientosProps) {
        super(props);
        this.state = {}
    }

    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("agradecimientos.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-dark">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center pt-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col md="8">
                                    <h1 className="display-3 text-white text-center">
                                        {Locale.format('agradecimientos.titular')}
                                    </h1>
                                    <p className="lead text-white text-center">
                                        {Locale.format('agradecimientos.subtitular')}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-secondary" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section bg-secondary">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="4">
                                <img className="img-fluid img-center" src={require("../assets/img/agradecimientos/azure.png")}
                                     alt="..."/>
                            </Col>
                            <Col md="4">
                                <img className="img-fluid img-center" src={require("../assets/img/agradecimientos/gsuite.png")}
                                     alt="..."/>
                            </Col>
                            <Col md="4">
                                <img className="img-fluid img-center" src={require("../assets/img/agradecimientos/sentry.svg")}
                                     alt="..."/>
                            </Col>
                        </Row>
                        <Row className="row-grid align-items-center">
                            <Col md="4">
                                <img className="img-fluid img-center" src={require("../assets/img/agradecimientos/github.png")}
                                     alt="..."/>
                            </Col>
                            <Col md="4">
                                <img className="img-fluid img-center" src={require("../assets/img/agradecimientos/vercel.svg")}
                                     alt="..."/>
                            </Col>
                            <Col md="4">
                                <img className="img-fluid img-center" src={require("../assets/img/agradecimientos/flickr.png")}
                                     alt="..."/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>;
    }
}