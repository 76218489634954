import React from "react";
import {Helmet} from "react-helmet";
import {Locale} from "../../i18n";
import {Badge, Card, CardBody, CardImg, Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";

interface FormacionProps {
}

interface FormacionState {
}

export class Formacion extends React.Component<FormacionProps, FormacionState> {
    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("nosotros.formacion.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-info">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6" className="order-lg-2">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('nosotros.formacion.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('nosotros.formacion.subtitular')}
                                    </p>
                                </Col>
                                <Col lg="6" className="order-lg-1">
                                    <img
                                        alt="..." className="img-fluid rounded shadow"
                                        src={require('../../assets/img/nosotros/formacion/p2.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                                <div className="pr-md-5">
                                    <div
                                        className="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faFlag}/>
                                    </div>
                                    <h3>{Locale.format("nosotros.formacion.inicial.titulo")}</h3>
                                    <p>{Locale.format("nosotros.formacion.inicial.subtitulo")}</p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="info">
                                                        <FontAwesomeIcon icon={FA.faPencilAlt}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.formacion.inicial.l1")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="info">
                                                        <FontAwesomeIcon icon={FA.faPencilAlt}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">
                                                        {Locale.format("nosotros.formacion.inicial.l2")}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="6">
                                <img alt="..." className="img-fluid shadow"
                                     src={require('../../assets/img/nosotros/formacion/s1.jpg')}/>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section pt-4 pb-5 bg-gradient-info">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col lg="6">
                                <div className="d-flex px-3">
                                    <div>
                                        <div
                                            className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-info">
                                            <FontAwesomeIcon icon={FA.faChalkboardTeacher}/>
                                        </div>
                                    </div>
                                    <div className="pl-4">
                                        <h4 className="display-3 text-white">{Locale.format("nosotros.formacion.interna.titulo")}</h4>
                                        <p className="text-white">{Locale.format("nosotros.formacion.interna.descripcion")}</p>
                                    </div>
                                </div>
                                <Card className="shadow shadow-lg--hover mt-3">
                                    <CardBody>
                                        <div className="d-flex px-3">
                                            <div>
                                                <div
                                                    className="icon icon-shape bg-gradient-info rounded-circle text-white">
                                                    <FontAwesomeIcon icon={FA.faComment}/>
                                                </div>
                                            </div>
                                            <div className="pl-4">
                                                <h5 className="title text-info">
                                                    {Locale.format('nosotros.formacion.interna.extra.titulo')}
                                                </h5>
                                                <p>
                                                    {Locale.format('nosotros.formacion.interna.extra.descripcion')}
                                                </p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="ml-lg-auto" md="6">
                                <div className="position-relative pl-md-5">
                                    <img
                                        alt="..." className="img-center img-fluid"
                                        src={require('../../assets/img/nosotros/formacion/s2.jpg')}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
                <section className="section">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                                <img alt="..." className="img-fluid floating shadow"
                                     src={require('../../assets/img/nosotros/formacion/s3.jpg')}/>
                            </Col>
                            <Col md="6">
                                <div className="pl-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faUserShield}/>
                                    </div>
                                    <h3>{Locale.format("nosotros.formacion.agasp.titulo")}</h3>
                                    <p className="lead">{Locale.format("nosotros.formacion.agasp.subtitulo")}</p>
                                    <p>{Locale.format("nosotros.formacion.agasp.p1")}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section bg-secondary">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                                <div className="pl-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-info shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faUserGraduate}/>
                                    </div>
                                    <h3>{Locale.format("nosotros.formacion.damos.1.titulo")}</h3>
                                    <p className="lead">{Locale.format("nosotros.formacion.damos.1.subtitulo")}</p>
                                    <p>{Locale.format("nosotros.formacion.damos.1.p1")}</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <Card className="bg-info shadow border-0">
                                    <CardImg alt="..." src={require("../../assets/img/nosotros/formacion/s4.jpg")} top/>
                                    <blockquote className="card-blockquote">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="svg-bg"
                                            preserveAspectRatio="none"
                                            viewBox="0 0 583 95"
                                        >
                                            <polygon className="fill-info" points="0,52 583,95 0,95"/>
                                            <polygon className="fill-info" opacity=".2" points="0,42 583,95 683,0 0,95"/>
                                        </svg>
                                        <h4 className="display-3 font-weight-bold text-white">
                                            {Locale.format("nosotros.formacion.damos.2.titulo")}
                                        </h4>
                                        <p className="lead text-italic text-white">
                                            {Locale.format("nosotros.formacion.damos.2.subtitulo")}
                                        </p>
                                    </blockquote>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>;
    }
}