import "core-js/stable";
import "regenerator-runtime/runtime";

import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import {LocalizedRouter} from "./i18n/comps";
import {AppLayout} from "./layout";
import App from './App';


const DOC: React.FC = () => {
    useEffect(() => {
        const s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/60184534a9a34e36b972b07b/1etfcslin';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode?.insertBefore(s1, s0);
    }, []);

    return <LocalizedRouter>
        <AppLayout>
            <App/>
        </AppLayout>
    </LocalizedRouter>;
};

ReactDOM.render(
    <DOC/>,
    document.getElementById('pcivil')
);


// No usamos el service worker de momento
serviceWorker.unregister();
