import React from "react";
import {Helmet} from "react-helmet";
import {Locale} from "../../i18n";
import {Col, Container, Row} from "reactstrap";

interface GaleriaProps {
}

interface GaleriaState {
}

export class Galeria extends React.Component<GaleriaProps, GaleriaState> {
    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;

        const script = document.createElement("script");
        script.src = "https://embedr.flickr.com/assets/client-code.js";
        script.async = true;
        document.body.appendChild(script);
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("comunicacion.galeria.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-primary">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('comunicacion.galeria.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('comunicacion.galeria.subtitular')}
                                    </p>
                                </Col>
                                <Col lg="5">
                                    <img
                                        alt="..." className="img-fluid rounded shadow"
                                        src={require('../../assets/img/comunicacion/galeria/p1.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section section-lg">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col>
                                <a
                                    data-flickr-embed="true" data-header="true" data-footer="true"
                                    href="https://www.flickr.com/photos/pc_compostela/albums/72157716126957466"
                                    title="Web"
                                >
                                    <img
                                        className="img-fluid rounded shadow" alt="Web"
                                        src="https://live.staticflickr.com/65535/50386248662_4383c96965_h.jpg"
                                    />
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>;
    }
}