import React from "react";
import {Helmet} from "react-helmet";
import {AppRoutes, Locale, Route} from "../../i18n";
import {Badge, Button, Card, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";

interface SomosProps {
}

interface SomosState {
}

export class Somos extends React.Component<SomosProps, SomosState> {
    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return <>
            <Helmet>
                <title>{Locale.format("nosotros.somos.titulo")} - {Locale.format("footer.copyright")}</title>
            </Helmet>
            <div className="position-relative">
                {/* shape Hero */}
                <section className="section section-sm section-hero section-shaped">
                    <div className="shape shape-style-1 bg-gradient-warning">
                        <span/> <span/> <span/> <span/> <span/>
                        <span/> <span/> <span/> <span/>
                    </div>
                    <Container className="shape-container d-flex align-items-center py-lg">
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col lg="6" className="order-lg-2">
                                    <h1 className="display-3 text-white">
                                        {Locale.format('nosotros.somos.titular')}
                                    </h1>
                                    <p className="lead text-white">
                                        {Locale.format('nosotros.somos.subtitular')}
                                    </p>
                                </Col>
                                <Col lg="6" className="order-lg-1">
                                    <img
                                        alt="..." className="img-fluid rounded shadow"
                                        src={require('../../assets/img/nosotros/somos/p1.jpg')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section section-lg">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2" md="6">
                                <img
                                    alt="..." className="img-fluid floating shadow shadow-lg--hover"
                                    src={require("../../assets/img/nosotros/somos/s1.jpg")}
                                />
                            </Col>
                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faHandsHelping}/>
                                    </div>
                                    <h3>{Locale.format('nosotros.somos.quienes.titulo')}</h3>
                                    <p className="lead">{Locale.format('nosotros.somos.quienes.subtitulo')}</p>
                                    <p>{Locale.format('nosotros.somos.quienes.p1')}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section section-lg bg-gradient-warning">
                    <Container>
                        <Row className="text-center justify-content-center">
                            <Col lg="10">
                                <h2 className="display-3 text-white">{Locale.format('nosotros.somos.diversidad.titulo')}</h2>
                                <p className="lead text-white">
                                    {Locale.format('nosotros.somos.diversidad.subtitulo')}
                                </p>
                            </Col>
                        </Row>
                        <Row className="row-grid mt-5">
                            <Col lg="4">
                                <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-warning">
                                    <FontAwesomeIcon icon={FA.faUserGraduate}/>
                                </div>
                                <h5 className="text-white mt-3">{Locale.format('nosotros.somos.diversidad.1.titulo')}</h5>
                                <p className="text-white mt-3">
                                    {Locale.format('nosotros.somos.diversidad.1.subtitulo')}
                                </p>
                            </Col>
                            <Col lg="4">
                                <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-warning">
                                    <FontAwesomeIcon icon={FA.faUserNurse}/>
                                </div>
                                <h5 className="text-white mt-3">{Locale.format('nosotros.somos.diversidad.2.titulo')}</h5>
                                <p className="text-white mt-3">
                                    {Locale.format('nosotros.somos.diversidad.2.subtitulo')}
                                </p>
                            </Col>
                            <Col lg="4">
                                <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-warning">
                                    <FontAwesomeIcon icon={FA.faUserPlus}/>
                                </div>
                                <h5 className="text-white mt-3">{Locale.format('nosotros.somos.diversidad.3.titulo')}</h5>
                                <p className="text-white mt-3">
                                    {Locale.format('nosotros.somos.diversidad.3.subtitulo')}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                            version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                        >
                            <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>
                <section className="section section-lg">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2" md="6">
                                <img
                                    alt="..." className="img-fluid floating shadow shadow-lg--hover"
                                    src={require("../../assets/img/nosotros/somos/s3.jpg")}
                                />
                            </Col>
                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                                        <FontAwesomeIcon icon={FA.faChalkboardTeacher}/>
                                    </div>
                                    <h3>{Locale.format('nosotros.somos.formacion.titulo')}</h3>
                                    <p>{Locale.format('nosotros.somos.formacion.subtitulo')}</p>
                                    <p className="lead">{Locale.format('nosotros.somos.formacion.p1')}</p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="warning">
                                                        <FontAwesomeIcon icon={FA.faGraduationCap}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">{Locale.format("nosotros.somos.formacion.l1")}</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="warning">
                                                        <FontAwesomeIcon icon={FA.faGraduationCap}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">{Locale.format("nosotros.somos.formacion.l2")}</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="warning">
                                                        <FontAwesomeIcon icon={FA.faGraduationCap}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">{Locale.format("nosotros.somos.formacion.l3")}</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="warning">
                                                        <FontAwesomeIcon icon={FA.faGraduationCap}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">{Locale.format("nosotros.somos.formacion.l4")}</h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="warning">
                                                        <FontAwesomeIcon icon={FA.faGraduationCap}/>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h6 className="mb-0">{Locale.format("nosotros.somos.formacion.l5")}</h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section section-lg pt-7 bg-secondary">
                    <Container>
                        <Card className="bg-gradient-warning shadow-lg border-0">
                            <div className="p-5">
                                <Row className="align-items-center">
                                    <Col lg="8">
                                        <h3 className="text-white">{Locale.format("nosotros.somos.unirse.titulo")}</h3>
                                        <p className="lead text-white mt-3">
                                            {Locale.format("nosotros.somos.unirse.descripcion")}
                                        </p>
                                    </Col>
                                    <Col className="ml-lg-auto" lg="3">
                                        <Button
                                            block
                                            className="btn-white"
                                            color="default"
                                            to={Route(AppRoutes.Formacion)}
                                            size="lg"
                                            tag={Link}
                                        >
                                            {Locale.format("nosotros.somos.unirse.boton")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Container>
                </section>
            </div>
        </>;
    }
}